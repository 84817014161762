import React, { useEffect, useState } from 'react';
import global from '../services/global';
import { DateTime } from "luxon";
import {
    Container,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Skeleton,
    Box,
    styled,
    Alert,
} from "@mui/material";
import { Email, Schedule, VerifiedUser } from '@mui/icons-material';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: 900,
    margin: '2rem auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

interface KycStatusItem {
    id: string;
    license: string;
    licenseRemarks: string;
    bankAccount: string;
    bankAccountRemarks: string;
    overallStatus: string;
}

interface NewUser {
    id: number;
    name: string;
    email: string;
    user_kyc_status: KycStatusItem[];
    createdon: string;
}

export default function NewUsers() {
    const [loading, setLoading] = useState(true);
    const [newUsers, setNewUsers] = useState<NewUser[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchNewUsers = async () => {
            try {
                const response = await global.httpGet('api/admin/users/list/new');
                console.log("Response: ", response)
                
                if (response?.users) {
                    response.users.forEach(user => console.log("KYC Status:", user.user_kyc_status));
                    response.users.forEach(user => console.log("Created On:", user.createdon));
                    response.users.forEach(user => console.log("User_ID:", user.id));
                    setNewUsers(response.users);
                } else {
                    throw new Error('Unexpected response structure');
                }
            } catch (err) {
                console.error("Error fetching new users:", err);
                setError('Failed to fetch new users');
                setNewUsers([]);
            } finally {
                setLoading(false);
            }
        };

        fetchNewUsers();
    }, []);

    function getRows() {
        return newUsers.map((user, index) => (
            <StyledTableRow key={user.id}>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        {index + 1}
                    </Box>
                </TableCell>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        <Email color="action" sx={{ marginRight: 1 }} />
                        {user.email}
                    </Box>
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.user_kyc_status?.[0]?.overallStatus || 'N/A'}</TableCell>
                <TableCell>
                    <Box display="flex" alignItems="center">
                        <Schedule color="action" sx={{ marginRight: 1 }} />
                        {
                            user.createdon 
                                ? DateTime.fromISO(user.createdon) 
                                    .setZone("America/New_York") 
                                    .toLocaleString(DateTime.DATETIME_SHORT) 
                                : "N/A"
                        }
                    </Box>
                </TableCell>
            </StyledTableRow>
        ));
    }

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom sx={{ marginTop: 4 }}>
                New Users (Last 15 Days)
            </Typography>

            {error && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {error}
                </Alert>
            )}

            <Paper>
                <StyledTableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="new users table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledHeadCell>ID</StyledHeadCell>
                                <StyledHeadCell>Email</StyledHeadCell>
                                <StyledHeadCell>Name</StyledHeadCell>
                                <StyledHeadCell>KYC Status</StyledHeadCell>
                                <StyledHeadCell>Created On</StyledHeadCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                        <Skeleton animation="wave" height={53} />
                                    </TableCell>
                                </TableRow>
                            ) : !newUsers.length ? (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography align="center">No New Users Found</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                getRows()
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Paper>
        </Container>
    );
}

import * as React from "react";
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Skeleton,
	Box,
	Button,
	Modal,
	Autocomplete,
	TextField,
	Checkbox,
	Grid,
} from "@mui/material";
import { DateTime } from "luxon";
import { DateRange } from 'react-date-range';

import GlobalService from "../../services/global";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const Transaction = ({ tx, index, setRefresh}: any) => {

	const getStatusColor = (identifier: string) => {
		if(identifier === 'rewarded' || identifier === 'successful' || identifier === "Deposit" || identifier === "Signup Reward" || identifier === "Thanksgiving Bonus" || identifier === "Gift") return '#4CAF50';
		if(identifier === 'rejected') return '#F44336';
		if(identifier === 'pending' || identifier === 'processing' || identifier === 'investing' || identifier === "Free Credit" || identifier  === "Loan Debit") return '#fbc02d';
		if(identifier === 'Withdraw') return '#2196F3';
		return '#000000';
    }

	return (
		<TableRow key={index}>
			<TableCell>{tx.transid}</TableCell>
			<TableCell>{tx.account_id ? tx.account_id : "N/A"}</TableCell>
			<TableCell>
				{tx.effectiveDate
				? DateTime.fromISO(tx.effectiveDate).setZone("America/New_York").toFormat("ccc, FF a")
				: "NA"}
			</TableCell>
			<TableCell>{tx.productCode}</TableCell>
			<TableCell>{parseFloat(tx.amount).toLocaleString()}</TableCell>
			<TableCell style={{color: getStatusColor(tx.status)}}>{tx.status}</TableCell>
			<TableCell>
				{DateTime.fromISO(tx.createdon).setZone("America/New_York").toFormat("ccc, FF a")}
			</TableCell>
			<TableCell style={{color: getStatusColor(tx.type)}} >{tx.type}</TableCell>
		</TableRow>
	)
}

const Transactions = ({userid, value}:{userid:number, value: number}) => {
	const [txs, setTxs] = React.useState<[]>([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(true);
	const [product_codes, setProductCodes] = React.useState([] as any);

	const productCodes = [
		"PRINCIPAL_PROTECTED",
		"FDIC_INSURED",
		"SPECIAL_OFFER",
		"SPECIAL_OFFER_2",
		"GOVT_BONDS",
		"NEEV_AGGRESSIVE"
	]
	const txTypes = [
		"Deposit",
		"Withdraw",
		"Recurring Deposit",
		"Signup Reward",
		"Weekly Earnings",
		"Free Credit Weekly Earnings",
		"Thanksgiving Bonus",
		"Gift",
		"Wire Credit",
		"Free Credit",
		"Loan Debit",
		"Referral Bonus"
	]
	const [tx_types, setTxTypes] = React.useState(txTypes.filter(tx => !['Weekly Earnings', 'Free Credit Weekly Earnings'].includes(tx)) as any);

	const [selectedDateRange, setSelectedDateRange] = React.useState({
		startDate: new Date(),
		endDate: new Date(),
		maxDate: new Date(),
		key: "selection",
		status: "cleared"
	});
	const [selectedDates, setSelectedDates] = React.useState(selectedDateRange);
	const [showDatePicker, setShowDatePicker] = React.useState(false);

	const handleDateChange = (ranges: any) => {
		setSelectedDateRange({...ranges.selection, status: 'selected'});
	}
	const handleDateChangeSubmit = async () => {
		setShowDatePicker(false);

		const adjustedStartDate = new Date(selectedDateRange.startDate);
		adjustedStartDate.setHours(0, 0, 0, 0);
		const adjustedEndDate = new Date(selectedDateRange.endDate);
		adjustedEndDate.setHours(23, 59, 59, 999);
		setSelectedDates(selectedDateRange);
	}


	React.useEffect(() => {
		const fetchTransactions = async () => {
			setIsLoading(true);
			let reqStr = `api/admin/transactions/${userid}?`;
			// reqStr += `include=account_id&`;
			if(sessionStorage.getItem("user-txs-product_codes")) {
				setProductCodes(sessionStorage.getItem("user-txs-product_codes")!.split(","));
				reqStr += `product_codes=${sessionStorage.getItem("user-txs-product_codes")}&`;
			}
			if(sessionStorage.getItem("user-txs-tx_types")) {
				setTxTypes(sessionStorage.getItem("user-txs-tx_types")!.split(","));
				reqStr += `tx_types=${sessionStorage.getItem("user-txs-tx_types")!.split(",")}&`;
			}
			if(sessionStorage.getItem("user-txs-from") && sessionStorage.getItem("user-txs-to")) {
				const from = new Date(sessionStorage.getItem("user-txs-from")!);
				const to = new Date(sessionStorage.getItem("user-txs-to")!);
				setSelectedDates({
					startDate: from,
					endDate: to,
					maxDate: new Date(),
					key: "selection",
					status: "selected"
				});
				reqStr += `from=${from.toISOString()}&to=${to.toISOString()}&`;
			}

			const data = await GlobalService.httpGet(reqStr);
			// console.log("API Response:", data);
			if (data && !data.isError && data.txs) {
				setTxs(data.txs);
			}
			setIsLoading(false);
		};
		if (refresh) {
			fetchTransactions();
			setRefresh(false);
		}
	}, [refresh]);

	function getRows() {
		let rows: any[] = [];
		let bal = 0;
		const depTxs = ["Deposit", "Signup Reward", "Recurring Deposit", "Weekly Earnings", "Free Credit Weekly Earnings", "Thanksgiving Bonus", "Gift", "Wire Credit", "Free Credit", "Referral Bonus"]
		const passedStatuses = ["successful", "rewarded"];

		const getSign = (type) => {
			if(depTxs.includes(type)) return "+";
			else return "-";
		}

		txs.map((tx: any, index) => {
			if(depTxs.includes(tx.type) && tx.type!=="Free Credit" && passedStatuses.includes(tx.status)) bal+=parseFloat(tx.amount);
			else if(tx.type!=="Loan Debit" && tx.type!=="Free Credit" && passedStatuses.includes(tx.status)) bal-=parseFloat(tx.amount);
			rows.push(
				<Transaction key={tx.transid} tx={tx} index={index} setRefresh={setRefresh} />
			);
		});
		
		rows.push(
			<TableRow key={"totalFreeCredits"}>
				<TableCell colSpan={3} style={{textAlign: "right"}} >Total (free credit not included):</TableCell>
				<TableCell>{bal}</TableCell>
				<TableCell />
				<TableCell />
				<TableCell />
			</TableRow>
		)
		return rows;
	}

	const handleSubmitFilters = async () => {
		let reqStr = `api/admin/transactions/${userid}?`;
		// console.log(new Date(selectedDates.startDate)., selectedDates.endDate.toDateString())
		if(selectedDates.status === "selected") {
			reqStr += `from=${selectedDates.startDate.toISOString()}&to=${selectedDates.endDate.toISOString()}&`;
		}
		if(product_codes.length) {
			reqStr += `product_codes=${product_codes.join(",")}&`;
		}
		if(tx_types.length) {
			reqStr += `tx_types=${tx_types.join(",")}`;
		}

		const data = await GlobalService.httpGet(reqStr);
		if (data && !data.isError && data.txs) {
			setTxs(data.txs);
		}
		
		sessionStorage.setItem("user-txs-product_codes", product_codes.join(","));
		sessionStorage.setItem("user-txs-tx_types", tx_types.join(","));
		if(selectedDates.status === "selected") {
			sessionStorage.setItem("user-txs-from", selectedDates.startDate.toISOString());
			sessionStorage.setItem("user-txs-to", selectedDates.endDate.toISOString());
		}
		else {
			sessionStorage.removeItem("user-txs-from");
			sessionStorage.removeItem("user-txs-to");
		}
	}

	const clearFilters = () => {
		const newRange = {
			startDate: new Date(),
			endDate: new Date(),
			maxDate: new Date(),
			key: "selection",
			status: "cleared"
		};
		setTxTypes([...[]]);
		setProductCodes([...[]]);
		setSelectedDates(newRange);
		setSelectedDateRange(newRange);
	}

	return (
		<>
			<Grid style={{ display: value === 1 ? "flex" : "none" }} container spacing={2} marginTop={2} marginBottom={2}>
				<Grid item xs={3}>
					<Box sx={{ border: '1px solid #ccc', borderRadius: '5px', p: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<Box sx={{ textAlign: 'center', flexGrow: 1 }}>
							{selectedDates.status === "selected" ? (
								selectedDates.startDate.toDateString() === selectedDates.endDate.toDateString() ? (
									DateTime.fromISO(selectedDates.startDate.toISOString()).toFormat("dd/MM/yyyy")
								) : (
									`${DateTime.fromISO(selectedDates.startDate.toISOString()).toFormat("dd/MM/yyyy")} - ${DateTime.fromISO(selectedDates.endDate.toISOString()).toFormat("dd/MM/yyyy")}`
								)
							) : (
								"Select dates"
							)}
						</Box>
						<Button onClick={() => setShowDatePicker(!showDatePicker)} variant="outlined" size="small" sx={{ height: '75%', ml: 1 }}>
							{'Edit'}
						</Button>
					</Box>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						multiple
						id="size-small-outlined-multi"
						size="small"
						limitTags={1}
						options={productCodes}
						getOptionLabel={(s) => s}
						onChange={(event, value) => setProductCodes(value)}
						value={product_codes}
						renderOption={(props, s, { selected }) => (
							<li {...props}>
								<Checkbox
									key={s}
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{s}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} label="Select product type" />
						)}
					/>
				</Grid>
				<Grid item xs={3.5}>
					<Autocomplete
						multiple
						id="size-small-outlined-multi"
						size="small"
						limitTags={2}
						options={txTypes}
						getOptionLabel={(s) => s}
						onChange={(event, value) => setTxTypes(value)}
						value={tx_types}
						renderOption={(props, s, { selected }) => (
							<li {...props}>
								<Checkbox
									key={s}
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{s}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} label="Select tx type" />
						)}
					/>
				</Grid>
				<Modal
					open={showDatePicker}
					onClose={() => setShowDatePicker(false)}
					aria-labelledby="date-range-modal"
					aria-describedby="date-range-modal"
				>
					<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', paddingBottom: '20px' }}>
						<DateRange
							onChange={handleDateChange}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={1}
							ranges={[selectedDateRange]}
							direction="horizontal"
						/>
						<div style={{ marginTop: '10px', textAlign: 'center' }}>
							<Button onClick={() => setShowDatePicker(false)}>Close</Button> {/* Button to close the modal */}
							<Button onClick={handleDateChangeSubmit} variant="contained" color="primary" style={{ marginLeft: '10px' }}>Done</Button> {/* Submit button */}
						</div>
					</div>
				</Modal>
				<Grid item xs={1}>
					<Button variant="contained" color="primary" onClick={handleSubmitFilters}>
						{'Apply'}
					</Button>
				</Grid>
				<Grid item xs={1}>
					<Button variant="outlined" color="error" onClick={clearFilters}>
						{'Clear'}
					</Button>
				</Grid>

			</Grid >

			<Box sx={{ marginBottom: 2, textAlign: "right" }}>
				<Typography variant="h6">
					Total Transactions: {txs.length}
				</Typography>
			</Box>

			<TableContainer component={Paper} style={{ display: value === 1 ? "flex" : "none" }}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Transaction Id</TableCell>
							<TableCell>Account ID</TableCell>
							<TableCell>Effective Date</TableCell>
							<TableCell>Product Code</TableCell>
							<TableCell>Amount</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Created On</TableCell>
							<TableCell>Type</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<Skeleton />
									<Skeleton animation="wave" />
									{/* <Skeleton animation={false} /> */}
								</TableCell>
							</TableRow>
						) : !txs.length ? (
							<TableRow>
								<TableCell colSpan={8}>
									<Typography>No Data</Typography>
								</TableCell>
							</TableRow>
						) : (
							getRows()
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>

	);
};

export default Transactions;
